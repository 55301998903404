
.sidebar-brand-text {
    font-size: 15px;
}

.sidebar .nav-item .nav-link i {
    margin-right: 0.5rem !important;
}

.nav-link {
    padding-top: 0.7rem !important;
    padding-bottom: 0rem !important;
}

hr {
    margin: 1rem !important;
}

.nav-link.active {
    color: #fff !important;
    font-weight: 700;
}
.nav-link.active i {
    color: #fff !important;
}
